

export enum CandidateStatus {
  PUBLISHED = "A", DRAFT = "D", INACTIVE = "I"
}

export const CandidateStatusOptions =
  [{id: 'A', name: 'Gepubliceerd'}, {id: 'I', name: 'Inactief'}, {id: 'D', name: 'Draft'}];


export enum CandidateAvailability {
  DIRECTLY = "D", LATENT = "L", WITHIN_A_MONTH = "<1", IN_A_MONTH = ">1"
}

export const CandidateAvailabilityOptions =
  [{id: 'D', name: 'Per direct'}, {id: 'L', name: 'Latent op zoek'}, {id: '<1', name: '< 1 maand'}, {id: '>1', name: '> 1 maand'}];

export const DiscStyleOptions =
  [{id: 'D', name: 'Hunter', icon: "square", class: "red"},
    {id: 'I', name: 'Relatie Manager', icon: "square", class: "yellow"},
    {id: 'S', name: 'Support', icon: "square", class: "green"},
    {id: 'C', name: 'Procesmatig', icon: "square", class: "blue"},
  ];

export const FunctionLevelOptions =
  [{id: 'M', name: 'Management'},{id: 'O', name: 'Operationeel'}];

export function getCandidateAvailabilityName(id: string | null | undefined) {
  if(id == null) return "";
  const option = CandidateAvailabilityOptions.find( item => item.id === id);
  return option?.name ?? "";
}

export const WorkLevelOptions =
  [ {id: 1, name: 'Junior'}, {id: 2, name: 'Medior'}, {id: 3, name: 'Senior'}];

export function getCandidateWorkLevelName(id: number | null | undefined) {
  if(id == null) return "";
  const option = WorkLevelOptions.find( item => item.id === id);
  return option?.name ?? "";
}

export function getCandidateDiscStyleName(id: string | null | undefined) {
  if(id == null) return "";
  const option = DiscStyleOptions.find( item => item.id === id);
  return option?.name ?? "";
}

export function getCandidateFunctionLevelName(id: string | null | undefined) {
  if(id == null) return "";
  const option = FunctionLevelOptions.find( item => item.id === id);
  return option?.name ?? "";
}
